import React, {useEffect, useState} from "react";
import axios from "axios"

import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";
import {useFacturaVenta} from '../../containers/views/Main/Gestion/Facturas/facturaventa-context'

import Autocomplete from '@material-ui/lab/Autocomplete';

const TablaFacturaProductos = ({tipoFactura}) => {  
    const { listadoProductosFacturaVenta, setListadoProductosFacturaVenta } = useFacturaVenta();  
    const [igic, setIgic] = useState(null)

    const jornadas = [
      "J1 lunes a viernes 08:00 14:00",
      "J2 lunes a viernes 14:00 20:00",
      "J3 lunes a viernes 20:00 02:00",
      "J4 festivo 08:00 14:00",
      "J5 festivo 14:00 20:00",
      "J6 festivo 20:00 02:00",
      "J7 sábado 08:00 14:00",
      "J8 lunes a viernes 02:00 08:00",
      "J9 festivo 02:00 08:00",
      "J10 inhábil 00:00 23:59"
    ]

    const handleChange = (e, v, r, params) => {       
      handleCellEditCommit({id: params.id, field: params.field, value: v })
  }

    useEffect(() => {   
      const fetchData = async () => {   
          const resIGIC = await axios(`${process.env.REACT_APP_API_URL}dic/diccionarios/igic`);               
          setIgic(resIGIC.data)
        }        
      fetchData();     
    },[]);

    const ocultarExtracoste = tipoFactura === "unica" || tipoFactura === "rectificativa" ? false : true

    const columns = [    
        { field: 'operativa', headerName: 'Operativa', width: 100, sortable: false, hide: true },
        { field: 'categoria', headerName: 'Categoría', width: 100, sortable: false, hide: true },
        { field: 'subcategoria', headerName: 'Subcategoría', width: 100, sortable: false, hide: true },
        { field: 'codigo', headerName: 'Código', width: 100, sortable: false, hide: true },
        { field: 'producto', headerName: 'Producto',
        /*
        renderEditCell: (params) => ( <TextField fullWidth value={params.value}  /> ),          
        */
        width: 300, sortable: true, editable: true },//EAN   
        { field: 'cantidad', headerName: 'Cantidad',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 100, sortable: true, editable: true },
        { field: 'precioUnidad', headerName: 'Precio ud.', type: 'number',
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),
        width: 110, sortable: true, editable: true }, 
        { field: 'descuento', headerName: 'Dto. %', type: 'number',
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),
        width: 95, sortable: false, editable: true },   

        { field: 'jornada', headerName: 'Jornada', type: 'singleSelect', 
            editable: true, width: 150, valueOptions: jornadas, hide: ocultarExtracoste
        },
        { field: 'importeExtraCoste', headerName: 'Extracoste', type: 'number', 
            editable: false, width: 100, hide: ocultarExtracoste
        },

        { field: 'baseImponible', headerName: 'Base Imponible', width: 120, type: 'number', sortable: false,
        valueFormatter: (params) => {
            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} €`;
          },
        },         
        {
          field: 'idIgic', headerName: 'IGIC %',width: 180,
          renderCell: (params) => {            
            return(
            <Autocomplete
              id={"combo-box-igic-facturas-venta-" + params.row.etiquetaIgic}
              options={igic}
              getOptionLabel={(option) => option.Etiqueta}
              style={{ width: '100%' }}
              renderInput={
                (paramsA) => 
                <TextField 
                  {...paramsA} 
                  label={params.row.etiquetaIgic} 
                  variant="outlined" 
                  size="small"      
                  fullWidth
                />
              }              
              onChange={(e, v, r) => handleChange(e, v, r, params)}  
            />)
            
          }
        },
        { field: 'Igic', headerName: 'IGIC. €', type: 'number', width: 100, sortable: true, editable: false},
        
        { field: 'Irpf', headerName: 'IRPF. %', type: 'number',
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),        
        width: 95, sortable: false, editable: true },
        
        { field: 'precioTotal', headerName: 'Importe total', width: 120, type: 'number', sortable: false,
        valueFormatter: (params) => {
            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} €`;
          },
        },
        { field: 'operador', headerName: 'Operador',
            editable: true, width: 100
        },
        { field: '', headerName: '', width: 80, sortable: false, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => {
                    const elementosNoEliminar = listadoProductosFacturaVenta.filter(el => el.id !== params.row.id)  
                    setListadoProductosFacturaVenta(elementosNoEliminar)                    
                    }}/>
              ), },
    ];  

    const calculaTotalFila = (campo, valor, fila) => {
      let cantidad =  campo === 'cantidad' ? valor : fila.cantidad
      let precio =    campo === 'precioUnidad' ? valor : fila.precioUnidad
      let igic =      campo === 'idIgic' ? valor : fila.Igic 
      let irpf =      campo === 'Irpf' ? valor : fila.Irpf ? fila.Irpf : 0
      let descuento = campo === 'descuento' ? valor : fila.descuento
      let jornadaAux = campo === 'jornada' ? valor : fila.jornada
      let jornada = "1"
      
      
      if(jornadas.indexOf(jornadaAux))     
        jornada=jornadas.indexOf(jornadaAux)+1;
        
      let porcExtraCoste = fila["extraCoste"+jornada] || 0
      
      let extraCoste = (cantidad * precio * porcExtraCoste / 100) - (cantidad * precio * (porcExtraCoste / 100) * (descuento / 100))
      //extraCoste = Number(extraCoste.toFixed(2));
      //console.log(extraCoste)
      //let factor = Math.pow(10, 3);
      //let value = Math.round(extraCoste * factor) / factor;
      //console.log(value)
      /*
      function roundToDecimals(value, decimals) {
        const factor = Math.pow(10, decimals); // Multiplica para evitar decimales
        return Math.round(value * factor) / factor; // Divide después de redondear
      }
        */
      

      let bImponible = cantidad*precio - (cantidad*precio*descuento/100)    
      //console.log(bImponible) 
      //value = Math.round(bImponible * factor) / factor;
      //console.log(value)
      let total = (bImponible + (bImponible*igic/100)) + (extraCoste + (extraCoste*igic/100)) - (bImponible*irpf/100) - (extraCoste*irpf/100);
      //total = Number(total.toFixed(2));
      //console.log(total)
      //value = Math.round(total * factor) / factor;
      //console.log(value)

      return { ...fila,  cantidad: cantidad, precio: precio, Igic: igic, Irpf: irpf, descuento: descuento, precioUnidad: precio, jornada: jornadaAux, 
        operador: fila.operador, producto: fila.producto, importeExtraCoste: extraCoste, baseImponible: bImponible, precioTotal: total
      };
    }
    
    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {  
          if (field === 'cantidad') {                       
            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) {    
                const newRow = calculaTotalFila(field, value, row);
                return newRow;
              }
              return row;
            });              
            setListadoProductosFacturaVenta(updatedRows)         
          }

          if (field === 'idIgic') {                       
            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) {  
                let newRow = calculaTotalFila(field, parseFloat(value.Valor), row);
                newRow.idIgic = value.Id
                return newRow;
              }
              return row;
            });              
            setListadoProductosFacturaVenta(updatedRows)         
          }

          if (field === 'Irpf') {                       
            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) {
                const newRow = calculaTotalFila(field, value, row);
                return newRow;
              }
              return row;
            });              
            setListadoProductosFacturaVenta(updatedRows)         
          }

          if (field === 'descuento') {                       
            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) {            
               const newRow = calculaTotalFila(field, value, row);
                return newRow;
              }
              return row;
            });              
            setListadoProductosFacturaVenta(updatedRows)         
          }

          if (field === 'precioUnidad') {                    
            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) {
                const newRow = calculaTotalFila(field, value, row);
                return newRow;
              }
              return row;
            });            
            setListadoProductosFacturaVenta(updatedRows)                   
          } 
          
          if (field === 'jornada'){
            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) { 
                const newRow = calculaTotalFila(field, value, row);
                return newRow;
              }
              return row;
            });              
            setListadoProductosFacturaVenta(updatedRows)        

          }

          if (field === 'operador'){

            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) {
                return { ...row,  operador: value};
              }
              return row;
            });
            setListadoProductosFacturaVenta(updatedRows)  
          }

          if(field === 'producto'){
            const updatedRows = listadoProductosFacturaVenta.map((row) => {
              if (row.id === id) {
                return { ...row,  producto: value};
              }
              return row;
            });
            setListadoProductosFacturaVenta(updatedRows)  
          }
        },
        [listadoProductosFacturaVenta],
    );

  return(     
        <>
        <Grid container  spacing={1} className="mt-1">
        <Grid item xs={12} className="" style={{height: 250}}>
            <DataGrid 
                disableColumnMenu={true}
                //disableColumnReorder={false}   
                rows={listadoProductosFacturaVenta} 
                columns={columns} 
                pageSize={100} 
                checkboxSelection = {false}
                hideFooter = {true}
                hideFooterPagination = {true}
                hideFooterRowCount = {true}
                hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit}
                />
        </Grid>
        </Grid>
        </>
    )
}
export default TablaFacturaProductos;