import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router";
import { CircularProgress } from '@material-ui/core';

import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import FormularioFacturaUnica from "./FormularioFacturaUnica";
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import {useFacturaVenta} from './facturaventa-context'

const FacturaUnica = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory(); 
    const [datos, setDatos] = useState();
    const [pdfFactura, setPdfFactura]= useState('');
    const { setDatosFactura } = useFacturaVenta(); 
  

    useEffect(() => {
      if (modo==="R") {
        history.push({
          pathname: `/administracion/ventas/facturasunicas/${id}/W`,       
        });
      }
    },[])

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respFacturaUnica = await axios( process.env.REACT_APP_API_URL + "facturasunicas/" + id);
            if (respFacturaUnica.data) {        
              //console.log(respFacturaUnica.data)
              setDatos(respFacturaUnica.data);
              setDatosFactura(respFacturaUnica.data)
              setPdfFactura(respFacturaUnica.data.urlDocumento);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const generarPDF =  () => {    
      setIsLoading(true);
        const fetchData = async () => {
          const respFacturaUnica = await axios( process.env.REACT_APP_API_URL + "facturasunicas/generapdf/" + id);
          //console.log(respFacturaUnica.data)
          if (respFacturaUnica.data) {
            setPdfFactura(respFacturaUnica.data);
            setIsLoading(false);
          }
        };
        fetchData();      
    }    
    
    const botoneraAcciones = [    
      {
        etiqueta: modo === "R" ? "Editar Venta" : "Cancelar",
        url: 
        typeof id === "undefined" ? `/administracion/ventas` :
        modo === "R" ? 
          `/administracion/ventas/facturasunicas/${id}/W` : 
          `/administracion/ventas`,
        activo: true,
      },
      {
        etiqueta: "Guardar",
        form:'formulario-FacturaUnica',
        activo: modo === "R" ? false : true,
      },
      {
        etiqueta: "Generar documento",
        function: generarPDF,
        activo: modo === "R" ? false : true,
      },
      {        
        etiqueta: "Ver documento",
        file: pdfFactura ? pdfFactura : "",
        activo: pdfFactura ? true : false,        
      }
    ];

    const urlsMigasdepan = [
      {titulo: "Facturas", colorTexto: "texto-comercial", urlTo: "/administracion/ventas"},
      {titulo: "Factura Única", colorTexto: "texto-comercial", urlTo: `/administracion/ventas/facturasunicas/${id}/${modo}`},
    ]

    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";
    const MySearchBar = () => {return (<SearchBar titulo="Administración" claseCabecera = "bg-gestion" buscar={false} imagenCabecera={"6-1-2-facturas-ventas.png"}/>)}

    return (
        <>
          <div className="col-8 col-main-home ">
            <MySearchBar />            
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">  
                      {modo !== "R" && 
                        <FormularioFacturaUnica />}   
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default FacturaUnica;